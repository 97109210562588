.wrapper {
  margin-top: var(--spacing-3);
  width: 100%;
  display: grid;
  align-items: start;
  justify-content: center;
  padding: var(--spacing-8) 0;
  min-height: calc(100vh - var(--spacing-nav-mobile));
  min-height: calc((var(--vh, 1vh) * 100) - var(--spacing-nav-mobile));
  margin-top: var(--spacing-nav-mobile);
}
.container {
  font-size: var(--text-copy);
  padding: var(--spacing-4);
  width: 100%;
  background-color: var(--palette-background);
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  place-items: center;
  gap: var(--spacing-16);
}

.emptyContainer div {
  font-size: 1.5rem;
  text-align: center;
}

.list {
  display: grid;
  gap: var(--spacing-4);
}

.form {
  display: grid;
  gap: var(--spacing-4);
  grid-auto-flow: row;
  width: 100%;
  grid-row: 1;
}

.title {
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.75rem;
  font-weight: normal;
}

.receipt {
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  display: grid;
  grid-auto-flow: row;
  gap: var(--spacing-4);
}

.line {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-4);
  justify-content: space-between;
}

@media (min-width: 45em) {
  .wrapper {
    min-height: calc(100vh - var(--spacing-nav));
    min-height: calc((var(--vh, 1vh) * 100) - var(--spacing-nav));
    margin-top: var(--spacing-nav);
  }
  .container {
    max-width: 50rem;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    place-items: start;
    gap: var(--spacing-8);
  }
  .form {
    border: 0;
    grid-row: auto;
    position: sticky;
    top: 5rem;
  }
}
