@keyframes animateIn {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animateOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.container {
  margin: 0;
  padding: 2rem 1em;
  border-top: solid 1px #edebe7;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translate3d(0, -100%, 0);
  opacity: 0;
  padding: 1em;
  position: fixed;
  border-top: none;
  z-index: var(--z-layer-9);
  display: flex;
  animation-name: animateIn;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  flex-direction: column;
  max-width: 22.44rem;
  height: auto;
  padding: 1em;
  background: var(--color-white);
  border-radius: 1px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.subscribed {
  display: none;
}

.close {
  animation-name: animateOut;
  animation-duration: 0.3s;
  animation-delay: 0s;
}

.header {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.header > svg:hover {
  cursor: pointer;
}

.body {
  padding: 0.25rem;
}

.description {
  overflow: hidden;
  max-height: 300ch;
  opacity: 1;
  transition: opacity 0.15s ease, max-height 0.3s ease;
}
.hide {
  max-height: 0;
  opacity: 0;
}
.description > p:first-of-type {
  padding-right: 1.75rem;
}
.bold {
  font-weight: 500;
}
.thankyou {
  overflow: hidden;
  max-height: 300ch;
  opacity: 1;
  transition: opacity 0.15s ease 0.15s, max-height 0.3s ease;
}

.body > form {
  padding-top: 1rem;
  max-height: 100%;
  opacity: 1;
  transition: opacity 0.15s ease, max-height 0.3s ease;
  overflow: hidden;
}
.hide > form {
  max-height: 0;
  opacity: 0;
}
.body > form > p {
  display: inline;
}
.body > form > div {
  padding-bottom: 1em;
  padding-top: 1em;
}

.body > form > div:last-child {
  padding: 0;
}

.body > form > div > label {
  padding-right: 1em;
}

@media only screen and (min-width: 35em) {
  .container {
    top: 5.5rem;
    right: 1rem;
  }
  .close {
    top: -5.75rem;
  }
}

@media only screen and (max-width: 35em) {
  .container {
    bottom: 1rem;
  }
}
